'use client';

import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Link from 'next/link';
import { Pagination, Navigation } from 'swiper';
import Image from 'next/image';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import SlideAfterMotion from '@/components/common/animation/SlideAfterMotion';

const FeaturesSection = ({featureData}) => {

  return (
    <div className="features_section_wrapper">
      <div className="container">
        <div className="top_arrow_slider">
          <div className="slider_top_title_wrapper">
            <SlideAfterMotion>
              <h5 className="slider_top_title">
                Lets check Out
                <b className="color_title"> New Feature</b>
              </h5>
            </SlideAfterMotion>
          </div>
          <Swiper
            slidesPerView={3}
            slidesPerGroup={2}
            spaceBetween={45}
            speed={1000}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              425: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              768: {
                slidesPerView: 1,
                slidesPerGroup: 1,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {featureData && featureData.map((item, index) => (
              <>
                <SwiperSlide key={index}>
                  <div className="slider_item_card_wrapper">
                    <div className="slide_image_wrapper">
                      <Image
                        src={item.image}
                        alt="Picturer"
                        fill
                        sizes="100vw"
                        style={{
                          objectFit: "contain",
                          objectPosition: "left",
                        }}
                      />
                    </div>
                    <SlideAfterMotion>
                      <div className="slide_card_body">
                        <h5 className="slide_card_title">{item.title}</h5>
                        <p className="slide_card_dec">{item.description}</p>
                      </div>
                    </SlideAfterMotion>
                  </div>
                </SwiperSlide>
              </>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
